import React, { useState, useEffect } from "react"
import {
  Wrapper,
  DescriptionWrapper,
  ImageWrapper,
  Rounded,
  NoImageWrapper,
  FormWrapper,
  RowWrapper,
  InputWrapper,
  Spacer,
  ButtonWrapper,
} from "./Modifier.module.css"
import { OrangeTitle } from "./../../../../css/Titre.module.css"
import {
  PaddingBottom10,
  PaddingTop30,
} from "./../../../../css/Padding.module.css"
import Button from "./../../../../components/Button"
import { classNames } from "./../../../../helpers/classNames"
import AddImage from "./../../../../res/add_image.png"
import Select from "./../../../../components/Select"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import firebase from "gatsby-plugin-firebase"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Modifier = ({ offer, shops }) => {
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)
  const [errorSnackbarIsOpened, setErrorSnackbarIsOpened] = useState(false)

  const [image, setImage] = useState({ preview: offer["image"], raw: "" })
  const [category, setCategory] = useState({
    value: offer["category"],
    label: offer["category"],
  })
  const [shop, setShop] = useState({ value: offer["shopId"], label: "" })
  const [title, setTitle] = useState(offer["title"])
  const [description, setDescription] = useState(offer["description"])
  const [welcomeOffer, setWelcomeOffer] = useState({
    label: offer["welcomeOffer"] ? "Oui" : "Non",
    value: offer["welcomeOffer"],
  })
  const [mask, setMask] = useState({
    label: offer["mask"] ? "Oui" : "Non",
    value: offer["mask"],
  })

  useEffect(() => {
    let idx = 0

    while (idx < shops.length) {
      if (offer["shopId"] === shops[idx]["id"]) {
        setShop({ value: shops[idx]["id"], label: shops[idx]["name"] })
        break
      }
      idx++
    }
  }, [shops, offer])

  const categories = [
    "Bars & Restauration",
    "Sports & Loisirs",
    "Mode & Bien être",
    "Services",
    "Jobs",
  ]
  const welcomeOffers = [
    { label: "Oui", value: true },
    { label: "Non", value: false },
  ]

  const maskPossibilities = [
    { label: "Oui", value: true },
    { label: "Non", value: false },
  ]

  const getNewOffer = imagePath => {
    return {
      image: imagePath,
      category: category.value,
      shopId: shop.value,
      welcomeOffer: welcomeOffer.value,
      description: description,
      title: title,
      isFavorite: offer["isFavorite"],
      index: Number(offer["index"]),
      categoryIndex: Number(offer["categoryIndex"]),
      mask: mask.value,
    }
  }

  const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  const handleErrorClose = () => {
    setErrorSnackbarIsOpened(false)
  }

  const handleValidateFormButton = () => {
    if (
      image.preview === "" ||
      title.length === 0 ||
      category.length === 0 ||
      shop.length === 0 ||
      !welcomeOffer ||
      description.length === 0
    ) {
      setErrorSnackbarIsOpened(true)
      return
    }
    if (image.preview !== offer["image"]) {
      firebase
        .storage()
        .ref(`/offers/${image.raw.name}`)
        .put(image.raw)
        .then(() => {
          firebase
            .storage()
            .ref(`/offers/${image.raw.name}`)
            .getDownloadURL()
            .then(imageUrl => {
              firebase
                .firestore()
                .collection("offers")
                .doc(offer["id"])
                .update(getNewOffer(imageUrl.toString()))
                .then(() => {
                  setSuccessSnackbarIsOpened(true)
                })
                .catch(updatedOfferError => {
                  //console.log("updatedOfferError: ", updatedOfferError)
                  setErrorSnackbarIsOpened(true)
                })
            })
            .catch(getUrlError => {
              //console.log("getUrlError: ", getUrlError)
              setErrorSnackbarIsOpened(true)
            })
        })
        .catch(uploadImageError => {
          //console.log("uploadImageError: ", uploadImageError)
          setErrorSnackbarIsOpened(true)
        })
    } else {
      firebase
        .firestore()
        .collection("offers")
        .doc(offer["id"])
        .update(getNewOffer(image.preview))
        .then(() => {
          setSuccessSnackbarIsOpened(true)
        })
        .catch(updatedShopError => {
          //console.log("updatedOfferError in else: ", updatedShopError)
          setErrorSnackbarIsOpened(true)
        })
    }
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Modifier l'offre</h1>
      <p className={DescriptionWrapper}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce in libero
        tincidunt, consectetur nulla ut, condimentum libero. Fusce et elementum
        sapien. Vivamus cursus sapien non dolor aliquet ullamcorper. Maecenas
        quis aliquam tellus. Fusce ac cursus lacus. Proin sodales nisl non augue
        dapibus suscipit. Vestibulum eu mattis augue. Orci varius natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed
        vitae arcu sit amet metus auctor pellentesque sit amet nec ante.
        Suspendisse sed diam ultrices, elementum felis in, blandit turpis.
        Nullam cursus ligula quis leo tincidunt bibendum.
      </p>
      <form className={FormWrapper}>
        <div className={ImageWrapper}>
          <label htmlFor="upload-button">
            {image.preview ? (
              <img className={Rounded} src={image.preview} alt="offer" />
            ) : (
              <div className={NoImageWrapper}>
                <img
                  src={AddImage}
                  alt="offer"
                  width="75"
                  height="75"
                  style={{ paddingLeft: "37px", paddingTop: "37px" }}
                />
              </div>
            )}
          </label>
          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleChange}
          />
        </div>
        <div className={RowWrapper}>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="title">Titre</label>
            </div>
            <input
              type="name"
              placeholder="ex: -10% sur ta prochaine commande"
              onChange={e => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <div className={Spacer}></div>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="category">Catégorie</label>
            </div>
            <Select
              placeholder="Sélectionnez une catégorie"
              options={categories.map(data => ({
                value: data,
                label: data,
              }))}
              onChange={e => setCategory(e)}
              value={category}
            />
          </div>
        </div>
        <div className={RowWrapper}>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="shop">Commerce</label>
            </div>
            <Select
              placeholder="Quel commerce propose cette offre ?"
              options={shops.map(shop => ({
                value: shop.id,
                label: shop.name,
              }))}
              onChange={e => setShop(e)}
              value={shop}
            />
          </div>
          <div className={Spacer}></div>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="welcomeOffer">Offre de bienvenue</label>
            </div>
            <Select
              placeholder="Cette offre est-elle une offre de bienvenue ?"
              options={welcomeOffers.map(data => ({
                value: data.value,
                label: data.label,
              }))}
              onChange={e => setWelcomeOffer(e)}
              value={welcomeOffer}
            />
          </div>
        </div>
        <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="mask">Masquée</label>
            </div>
            <Select
              placeholder="Cette offre est-elle masquée ?"
              options={maskPossibilities.map(data => ({
                value: data.value,
                label: data.label,
              }))}
              onChange={e => setMask(e)}
              value={mask}
            />
          </div>
        <div className={InputWrapper}>
          <div className={PaddingBottom10}>
            <label htmlFor="description">Description</label>
          </div>
          <textarea
            type="message"
            placeholder="Décris l’offre ici..."
            onChange={e => setDescription(e.target.value)}
            value={description}
          />
        </div>
        <div className={classNames([PaddingTop30, RowWrapper])}>
          <div className={Spacer}></div>
          <div className={ButtonWrapper}>
            <Button alt="" onClick={() => handleValidateFormButton()}>
              Ajouter
            </Button>
          </div>
        </div>
      </form>
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bravo ! Tu as bien modifié cette offre !
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Oops... Les infos que tu as rentré ne sont pas correctes.
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Modifier
