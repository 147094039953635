// extracted by mini-css-extract-plugin
export var ButtonWrapper = "Modifier-module--ButtonWrapper--rNCBs";
export var DescriptionWrapper = "Modifier-module--DescriptionWrapper--R4Tra";
export var FormWrapper = "Modifier-module--FormWrapper--tYkmd";
export var ImageWrapper = "Modifier-module--ImageWrapper--OJGVO";
export var InputWrapper = "Modifier-module--InputWrapper--Fi-Db";
export var NoImageWrapper = "Modifier-module--NoImageWrapper--BIajE";
export var Rounded = "Modifier-module--Rounded--oxvpv";
export var RowWrapper = "Modifier-module--RowWrapper--byyvU";
export var Spacer = "Modifier-module--Spacer--ogKGK";
export var Wrapper = "Modifier-module--Wrapper--6Zoq1";