import React, { useState, useEffect } from "react";
import { getUrlParams } from "./../../../utils/utils";
import Layout from "./../../../components/Layout";
import Modifier from "./../../../nav/Admin/Offres/Modifier";
import { Helmet } from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import { getUser } from "./../../../auth";
import Error403 from "../../../nav/Error403";
import { TailSpin } from "react-loader-spinner";
import { getOfferFormatted } from "../../../utils/formating";

const HandleUserStates = ({ user, offer, shops }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (user && user.subscription.type === "admin") {
    return <Modifier offer={offer} shops={shops} />;
  } else {
    return <Error403 />;
  }
};

const ModifierPage = () => {
  const [user, setUser] = useState(undefined);
  const [offer, setOffer] = useState(null);
  const [shops, setShops] = useState([]);

  useEffect(() => {
    getUser(setUser);
    const id = getUrlParams("id");
    firebase
      .firestore()
      .collection("offers")
      .doc(id)
      .get()
      .then((doc) => {
        setOffer(getOfferFormatted(doc));
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
    firebase
      .firestore()
      .collection("shops")
      .get()
      .then((docs) => {
        let idx = 0;
        let newShops = [];

        while (idx < docs.docs.length) {
          newShops.push({
            name: docs.docs[idx]._delegate._document.data.value.mapValue.fields
              .name.stringValue,
            id: docs.docs[idx].id,
          });
          idx++;
        }
        setShops(newShops);
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Modifier l'offre</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu pourras ici modifier l'offre."
        />
        <meta
          name="keywords"
          content="réductions,jeunes,admin,offres utilisées,utilisateur"
        />
        <link rel="canonical" href="https://wynit.com/admin/offres/modifier" />
      </Helmet>
      <HandleUserStates user={user} offer={offer} shops={shops} />
    </Layout>
  );
};

export default ModifierPage;
